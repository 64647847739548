import React from "react"
import "./YoutubeVideo.scss"

const YOUTUBE_URL = "www.youtube-nocookie.com"
const YOUTUBE_NOCOOKIE_URL = "www.youtube-nocookie.com"

const VIDEO_HOSTING_URL = `https://${YOUTUBE_NOCOOKIE_URL}/embed`

const DEFAULT_ENGLISH_VIDEO_LINK = `${VIDEO_HOSTING_URL}/Mr39WAAAH2g`

export const YOUTUBE_VIDEO_LINKS = {
  by: `${VIDEO_HOSTING_URL}/JS81mx0ltkM`,
  cy_en: DEFAULT_ENGLISH_VIDEO_LINK,
  en_am: `${VIDEO_HOSTING_URL}/fhA7H3F2xTw`,
  lt_en: DEFAULT_ENGLISH_VIDEO_LINK,
  lt_lt: `${VIDEO_HOSTING_URL}/vehPcz50n_Q`,
}

function YoutubeVideo({ videoSrc }) {
  return (
    <section className="youtube-video fullscreen">
      <iframe
        src={videoSrc + "?controls=0"}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </section>
  )
}

export default YoutubeVideo
